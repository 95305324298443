import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import HeadSection from "../components/HeadSection";
import About from "../components/About";
import Experience from "../components/Experience";
import Projects from "../components/Projects";
import Cta from "../components/Cta";
import MediaLinks from "../components/MediaLinks";

const HomePage = () => {

    return (
        <HelmetProvider>
            <Helmet>
                <title>Mykyta Melnyk - Frontend Developer [CV]</title>
                <meta name="description" content="Welcome to the professional portfolio of Mykyta Melnyk, a dedicated frontend developer. Explore Mykyta's skills in HTML, CSS, JavaScript, React, PHP and more, showcased through innovative projects and a commitment to responsive design. View Mykyta's CV and discover how expertise and passion converge to create exceptional web experiences."/>
                <meta property="og:url" content="/"/>
            </Helmet>

            <HeadSection/>

            <About/>

            <Experience/>

            <Projects/>

            <Cta/>

            <MediaLinks/>

        </HelmetProvider>
    );
};

export default HomePage;
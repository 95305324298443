import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../styles/error.scss';


const ErrorPage = () => {

    return (
        <HelmetProvider>
            <Helmet>
                <title>404 page</title>
                <meta name="description" content='We can not find the page...'/>
                <meta property="og:url" content="/"/>
            </Helmet>

            <div className="error_page">
                <div className="noise"></div>
                <div className="overlay"></div>
                <div className="terminal">
                    <h1>Error <span className="errorcode">404</span></h1>
                    <p className="output">The page you are looking for might have been removed, had its name changed or
                        is
                        temporarily unavailable.</p>
                    <p className="output">Please try to <a href="/">return to the homepage</a>.
                    </p>
                    <p className="output">Good luck.</p>
                </div>
            </div>

        </HelmetProvider>
    );
};

export default ErrorPage;
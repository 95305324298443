import React from 'react';
import '../styles/speed_test.scss';
import GaugeComponent from "react-gauge-component";

const SpeedBlock = (props) => {

    const speedLevel = props.speed;
    const speedRate = (value) => {
        if (value > 100) {
            value = value / 100;
            if (Number.isInteger(value)) {
                return value.toFixed(0) + '/100';
            } else {
                return value.toFixed(1) + '/100';
            }
        } else {
            return value.toFixed(0) + '/100';
        }
    }

    return (
        <div className={'chart_speed_container'}>
            <GaugeComponent
                arc={{
                    nbSubArcs: 150,
                    colorArray: ['#f33', '#fa3', '#B8FF01'],
                    width: 0.1,
                    padding: 0.003
                }}
                labels={{
                    valueLabel: {
                        fontSize: 40,
                        formatTextValue: speedRate
                    },
                    tickLabels: {
                        type: "outer",
                        ticks: [
                            { value: 0 },
                            { value: 10 },
                            { value: 20 },
                            { value: 30 },
                            { value: 40 },
                            { value: 50 },
                            { value: 60 },
                            { value: 70 },
                            { value: 80 },
                            { value: 90 },
                            { value: 100 },
                        ],
                        valueConfig: {
                            formatTextValue: speedRate
                        }
                    }
                }}
                value={speedLevel}
                maxValue={100}
            />
        </div>
    );
};

export default SpeedBlock;
import React, {useState} from 'react';
import {useSpring, animated} from 'react-spring';
import Me from '../img/nick_n.jpg';
import MeMob from '../img/nick_nm.jpg';
import '../styles/header_section.scss'
import Button from "./Button";


const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const calc = (x, y) => {
    const BUFFER = 60;

    const why = -(y - window.innerHeight / 2) / BUFFER;
    const ex = (x - window.innerWidth / 2) / BUFFER;

    return [why, ex, 1];
};

const HeadSection = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: {mass: 1, tension: 350, friction: 20}
    }));

    const fotoDefolt = () => {
        set({xys: [0, 0, 1]})
    }


    return (
        <div className={'container-header head_section'}>
            <div className={'head_section__textContainer'}>
                <span className={'head_underTitle'}>Hi, it’s</span>
                <h1>
                    <span>Mykyta Melnyk,</span>
                    <span className={'color_second'}>I create progressive web apps</span>
                </h1>
                <div className={'header-text'}>
                    <p>
                        Web developer with a focus on quality and performance.
                        I specialize in developing attractive and functional web interfaces
                        using cutting edge technologies and optimizing the structure
                        of high-traffic projects to ensure maximum performance.
                    </p>
                    <span className={'img-mob-container'}>
                    <img src={MeMob} className={`mob-image ${isHovered ? 'hovered' : ''}`} alt="Mykyta Melnyk"/>
                    </span>
                </div>


                <Button text={'Download resume'}
                        styleClass={'btn_red'}
                        link={'/cv_mykyta_melnyk.pdf'}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        downloadMod={'download'}/>

            </div>
            <div className={`head_section__img`}>
                <animated.div
                    className="card"
                    onMouseMove={e => {
                        const {clientX: x, clientY: y} = e;
                        set({xys: calc(x, y)});
                    }}
                    style={{transform: props.xys.interpolate(trans)}}
                    onMouseLeave={fotoDefolt}
                >
                    <img src={Me} className={`head_section__img ${isHovered ? 'hovered' : ''}`} alt="Mykyta Melnyk"/>
                </animated.div>

            </div>
        </div>
    );
};

export default HeadSection;
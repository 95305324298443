import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import MediaLinks from "../../components/MediaLinks";
import '../../styles/project_page.scss';
import TabsSpeed from "../../components/subComponents/TabsSpeed";

import Pechnik from '../../img/project_img/pechnik/logo.svg';
import FrameDesctop from '../../img/desc_c.png';
import FrameMob from '../../img/mob_c.png';
import Mob from '../../img/project_img/pechnik/mob.jpg';
import Desc from '../../img/project_img/pechnik/desc.jpg';
import VideoFile from '../../img/project_img/pechnik/video.mp4';

const SemproPage = () => {
    const tabs = [
        {
            label: 'Desctop',
            img: Desc,
            frame: FrameDesctop,
            speed: '98',
            class: 'desctop_prev'
        },
        {
            label: 'Mobile',
            img: Mob,
            frame: FrameMob,
            speed: '95',
            class: 'mob_prev'
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>Developed Web Application - Pechnik</title>
                <meta name="description"
                      content='Finished website - Pechnik designed to streamline your business operations. Built with the latest technologies, this user-friendly and efficient website showcases my expertise in web development. Learn more about my process and how we can help your business thrive online. '/>
                <meta property="og:url" content="/projects/pechnik"/>
            </Helmet>

            <div className='project_container inner_section'>
                <div className="container">
                    <div className="bread">
                        <a href='/projects'>to /projects</a>
                    </div>


                    <div className="project_title">
                        <div className="logo">
                            <img src={Pechnik} alt="pechnik" className='project_logo add_bg'/>
                            <p className="project_sub">
                                Pechnik-24.ru - Barbecues, Fireplaces, stoves.
                            </p>
                        </div>

                        <div className="problem_description">
                            <p>The website was needed for the effective representation of the company, increasing sales
                                volume, and enhancing brand recognition. The website was created to improve customer
                                interaction, simplify the ordering process, and provide comprehensive information about
                                the services offered and the portfolio of completed projects.</p>
                        </div>

                        <div className="top_achievements">
                            <div className="achievement">
                                <p className="count">
                                    20
                                </p>
                                <p className="count_sub">
                                    Days for Development
                                </p>
                            </div>
                            <div className="achievement">
                                <p className="count">
                                    98
                                </p>
                                <p className="count_sub">
                                    Page Load Speed
                                </p>
                            </div>
                            <div className="achievement">
                                <p className="count">
                                    100%
                                </p>
                                <p className="count_sub">
                                    Unique Design
                                </p>
                            </div>
                        </div>

                        <div className="solution">
                            <div className={'title_solution'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="323" height="105">
                                    <text x="0" y="50%">Solution</text>
                                </svg>
                            </div>

                            <p>To meet the company's needs for effective representation, increased sales volume, and
                                enhanced brand recognition, the website development focused on several key areas:</p>

                            <ul className={'project_solution'}>
                                <li><span>Professional Representation:</span> The website was designed with a modern and professional
                                    aesthetic to showcase the company's expertise and reliability.
                                </li>
                                <li><span>Enhanced Sales:</span> A gallery of completed projects with a convenient filter was
                                    integrated.
                                </li>
                                <li><span>Brand Recognition:</span> The website included consistent branding elements, including
                                    logos, color schemes, and messaging, to strengthen brand identity. High-quality
                                    images and compelling content were used to convey the company's unique selling
                                    points and differentiate it from competitors.
                                </li>
                                <li><span>Customer Interaction:</span> Improved communication channels such as a Telegram chat and a
                                    short feedback form were established. This ensured prompt and efficient responses to
                                    customer needs.
                                </li>
                                <li><span>Comprehensive Information:</span> The website provided extensive information about the
                                    company's services. Additionally, a portfolio section showcased completed projects.
                                </li>
                            </ul>

                            <div className="video_project">
                                <div className="video_container">
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        poster=""
                                        className="fullscreen-bg__video"
                                    >
                                        <source src={VideoFile} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>

                            <p>After launching the ad campaign for the landing page, the conversion rate was 14.2%,
                                which is an excellent result. We achieved optimal load speed despite the use of numerous
                                animations and streaming video on the page.</p>

                            <div className="project_speed">

                                <TabsSpeed tabs={tabs}/>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <MediaLinks/>
        </HelmetProvider>
);
};

export default SemproPage;
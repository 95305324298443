import React from 'react';
import Logo from '../../img/logotype.svg'
import '../../styles/footer.scss'

const Footer = () => {
    return (
        <footer className={'container-footer section-footer'}>
            <div className="container">
                <div className="footer-container">
                    <div className={'footer-col logo-col'}>
                        <img src={Logo} alt="Web developer" className="logo" />
                        <p className={'copyright'}>2015-2024</p>
                    </div>
                    <div className={'footer-col'}>
                        <div className={'footer-col-title'}>
                            Main
                        </div>
                        <ul>
                            <li><a href="/#about">About</a></li>
                            <li><a href="/#experience">Experience</a></li>
                            <li><a href="/#portfolio">Portfolio</a></li>
                            <li><a href="/projects">Projects</a></li>
                            <li><a href="/#contacts">Contacts</a></li>
                        </ul>
                    </div>
                    <div className={'footer-col'}>
                        <div className={'footer-col-title'}>
                            Contacts
                        </div>
                        <div className={'contact-container'}>
                            <div className={'contact-element'}>
                                <p>Telegram</p>
                                <a href="https://t.me/Nick_Dev">@Nick_Dev</a>
                            </div>
                            <div className={'contact-element'}>
                                <p>Instagram</p>
                                <a href="https://www.instagram.com/happywebdev/">@HappyWebDev</a>
                            </div>
                            <div className={'contact-element'}>
                                <p>E-mail</p>
                                <a href="mailto:mykytam91@gmail.com">mykytam9@gmail.com</a>
                            </div>
                            <div className={'contact-element'}>
                                <p>LinkedIn</p>
                                <a href="https://www.linkedin.com/in/melnykmykyta/">/melnykmykyta</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import MediaLinks from "../../components/MediaLinks";
import '../../styles/project_page.scss';
import TabsSpeed from "../../components/subComponents/TabsSpeed";

import Sempro from '../../img/project_img/sempro/logo.svg';
import FrameDesctop from '../../img/desc_c.png';
import FrameMob from '../../img/mob_c.png';
import Mob from '../../img/project_img/sempro/site_mob1.jpg';
import Desc from '../../img/project_img/sempro/site_desc1.jpg';
import VideoFile from '../../img/project_img/sempro/canf3.mp4';

const SemproPage = () => {
    const tabs = [
        {
            label: 'Desctop',
            img: Desc,
            frame: FrameDesctop,
            speed: '100',
            class: 'desctop_prev'
        },
        {
            label: 'Mobile',
            img: Mob,
            frame: FrameMob,
            speed: '90',
            class: 'mob_prev'
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>Developed Web Application - Sempro Conference</title>
                <meta name="description"
                      content='Explore the custom-built web application for Sempro Conference, designed to enhance attendee experience and streamline event management. Discover how our development team leveraged cutting-edge technologies to create a seamless and engaging platform. Learn more about our approach and success in delivering tailored solutions for conferences and events'/>
                <meta property="og:url" content="/projects/sempro"/>
            </Helmet>

            <div className='project_container inner_section'>
                <div className="container">
                    <div className="bread">
                        <a href='/projects'>to /projects</a>
                    </div>


                    <div className="project_title">
                        <div className="logo">
                            <img src={Sempro} alt="sempro" className='project_logo'/>
                            <p className="project_sub">
                                Sempro.com.ua - the world's most iconic Affiliate conference
                            </p>
                        </div>

                        <div className="problem_description">
                            <p>The task was to create a landing page for the conference within a very short timeframe.
                                The project includes a lot of animations, so it was crucial to ensure the site loads as
                                quickly as possible. The client requested a complex form for ticket purchase and conference registration.</p>
                        </div>

                        <div className="top_achievements">
                            <div className="achievement">
                                <p className="count">
                                    10
                                </p>
                                <p className="count_sub">
                                    Days for development
                                </p>
                            </div>
                            <div className="achievement">
                                <p className="count">
                                    100%
                                </p>
                                <p className="count_sub">
                                    Page load speed
                                </p>
                            </div>
                            <div className="achievement">
                                <p className="count">
                                    14.2%
                                </p>
                                <p className="count_sub">
                                    Traffic conversion
                                </p>
                            </div>
                        </div>

                        <div className="solution">
                            <div className={'title_solution'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="323" height="105">
                                    <text x="0" y="50%">Solution</text>
                                </svg>
                            </div>

                            <p>The project was developed and launched in just 10 days. For user convenience, a payment
                                system was integrated to allow for ticket purchases. All information about registered
                                attendees is stored in a database. An enhanced version of the existing admin panel is
                                used for content management.</p>

                            <div className="video_project">
                                <div className="video_container">
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        poster=""
                                        className="fullscreen-bg__video"
                                    >
                                        <source src={VideoFile} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>

                            <p>After launching the ad campaign for the landing page, the conversion rate was 14.2%,
                                which is an excellent result. We achieved optimal load speed despite the use of numerous
                                animations and streaming video on the page.</p>

                            <div className="project_speed">

                                <TabsSpeed tabs={tabs}/>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <MediaLinks/>
        </HelmetProvider>
    );
};

export default SemproPage;
import React from 'react';
import '../styles/media_links.scss';
import Image from "./Image";
import TelegramIcon from '../img/media/telegram_o.svg';
import InstagramIcon from '../img/media/insta.svg';
import MailOutlineIcon from '../img/media/email.svg';
import Linkedin from '../img/media/linkedin.svg';

const MediaLinks = () => {

    return (
        <div className={'media-container'}>
            <a href="https://t.me/Haterobots">
                <Image src={TelegramIcon} alt={`telegram`}/>
            </a>
            <a href="https://www.instagram.com/happywebdev/" target={'blank'}>
                <Image src={InstagramIcon} alt={`instagram`}/>
            </a>
            <a href="mailto:mykytam91@gmail.com" >
                <Image src={MailOutlineIcon} alt={`mail`}/>
            </a>
            <a href="https://www.linkedin.com/in/melnykmykyta/">
                <Image src={Linkedin} alt={`Linkedin`}/>
            </a>
        </div>
    );
};

export default MediaLinks;
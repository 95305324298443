import React from 'react';

import '../styles/experience.scss';
import TabsSection from "./subComponents/TabsComponent";

const Experience = () => {
    const tabs = [
        {
            label: 'Trionika',
            title: 'Front-end developer',
            date: 'Jul 2021 - Present',
            list: [
                'Maintaining and improving high-load website with over 100k pages.',
                'Performing regular scheduled maintenance, including updating the core, theme, and plugins, on websites built on the WordPress platform of 53 client websites. This proactive approach has resulted in a 10% improvement in clients websites average loading speed, enhancing the user experience and fortifying the sites against potential security vulnerabilities.',
                'Developed and launched websites using WordPress, React and Smarty. The utilization of cutting-edge technologies resulted in a 30% reduction in the speed of launching new websites and a 25% increase in user engagement. It also improved page average load speed up to 20%.',
                'Developed a flexible web application for the partner network with customizable features. Redesigned the client cabinet of partner networks to enhance conversion rates and improve usability.'
            ]
        },
        {
            label: 'Dadvi',
            title: 'Front-end developer',
            date: 'Aug 2020 - Jul 2021',
            list: [
                'Engineered high-performance landing-page websites meticulously optimized for contextual advertising and SEO.',
                'Developed e-commerce websites with the capability to integrate with Bitrix 1C.',
                'Strategically optimized and implemented impactful changes on clients\' websites in close collaboration with the marketing team. This collaborative effort resulted in a 50% increase in website traffic, a 15% improvement in conversion rates. By aligning with the marketing team\'s objectives, we achieved a 30% boost in the effectiveness of online campaigns, driving tangible and measurable success for our clients.',
            ]
        },
        {
            label: 'Dadvi(SEO)',
            title: 'SEO specialist (team lead)',
            date: 'Jan 2016 - Aug 2020',
            list: [
                'Devised and implemented SEO and content strategies for 84 client websites, resulting in up to 200%increase in organic search traffic. Additionally, our content strategy led to a 20% boost in user engagement, enhancing the overall visibility and performance of the client websites in the digital landscape.',
                'As the leader of the SEO department, I managed and guided a team of three professionals. During my tenure, we successfully executed comprehensive SEO strategies for client websites in highly competitive niches, including \'luxury real estate\' and \'DDoS protection,\' achieving a remarkable 160%increase in organic traffic. Through targeted SEO strategies, we secured a 30% rise in keyword rankings,propelling the websites to the top of search engine results. This accomplishment not only enhanced the visibility of our clients in competitive markets but also resulted in a 25% surge in online inquiries and conversions, solidifying our success in dominating these challenging sectors.',
                'Under my leadership, the SEO team played a pivotal role in achieving these measurable successes, showcasing the effectiveness of our strategies and the impact of a well-coordinated team.',
            ]
        },
    ];

    return (
        <div className='container section_experience section_detect' id={'experience'}>
            <div className={'home_section'}>
                <h2 className={'section_title'}>
                    2. Experience
                </h2>
                <TabsSection tabs={tabs} />
            </div>
        </div>
    );
};

export default Experience;
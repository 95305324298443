import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import '../../styles/tabs.scss';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >

            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function TabsSection ({ tabs }) {
    const [value, setValue] = React.useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 991);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 991);
        };
    }, []);

    if (!tabs || tabs.length === 0) {
        return <span>No tabs available</span>;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={'tab_container'}>
            <Box sx={{ display: 'flex' }} >
                <Tabs
                    orientation={isSmallScreen ? '' : 'vertical'}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderColor: 'divider' }}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} {...a11yProps({index})}/>
                    ))}
                </Tabs>

                {tabs.map((tab, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        <h3 className='work_position'>{tab.title}</h3>
                        <span className='work_period'>{tab.date}</span>
                        <ul className='work_achievements'>
                            {tab.list.map((item, itemIndex) => (
                                <li key={itemIndex}>{item}</li>
                            ))}
                        </ul>
                    </TabPanel>
                ))}
            </Box>
        </div>
    );
}

import React from 'react';
import '../../styles/projects.scss';
import LinkExternal from '../../img/external-link.svg';
import Image from "../Image";
import useInViewBlock from './UseInViewBlock';

const ProjectItem = ({image, title, description, tags, link, index, project_name, projecctLink}) => {
    const isReversed = index % 2 === 1;
    const [ref, inView] = useInViewBlock();

    const replacedDescription = description.split('<tag>').map((part, index) => {
        if (index % 2 === 1) {
            return <span key={index} className="highlighted">{part}</span>;
        } else {
            return part;
        }
    });

    return (
        <div ref={ref} className={`project_block ${isReversed ? 'revers' : ''} ${inView ? 'active' : ''}`}>
            <Image src={image} alt={project_name} classStyle={'project_img'}/>
            <div className='project_block__content'>
                <div className={'project_title_container'}>

                    {projecctLink ? (
                        <p className='project_name read_more_pro'><a href={projecctLink}>{title}</a></p>
                    ) : (
                        <p className={'project_name'}>{title}</p>
                    )}

                    <div className='project_block__description'>
                        <p>{replacedDescription}</p>
                    </div>
                </div>

                <div className='project_block__footer'>
                    <div className="tags">
                        {tags.map((tag, index) => (
                            <span key={index}>{tag}</span>
                        ))}
                    </div>
                    {link && (
                        <a href={link}><img src={LinkExternal} alt="external link" width={'29'} height={'29'}/></a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectItem;

import React, {useState, useEffect, useCallback} from 'react';
import Logo from '../../img/logotype.svg'
import '../../styles/header.scss'
import MediaLinks from "../MediaLinks";


const Header = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [activeSection, setActiveSection] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const menuItems = [
        { id: 'about', label: 'About' },
        { id: 'experience', label: 'Experience' },
        { id: 'portfolio', label: 'Portfolio' },
        { id: 'contacts', label: 'Contacts' },
    ];


    // mobile hamburger animation
    const handleClick = () => {
        setOpen(!isOpen);
    };

    // scroll animation
    const scrollToSection = useCallback((id) => {
        setOpen(!isOpen);
        const yOffset = -150;
        const element = document.getElementById(id);

        if (window.location.pathname !== '/') {
            let block = '/#' + id;
            window.location.href = block;
        } else {
            if (element) {
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }


    }, [isOpen]);

    useEffect(() => {
        const hash = window.location.hash.substr(1); // Получаем хэш-фрагмент без символа '#'

        if (hash) {
            window.onload = () => {
            scrollToSection(hash)
            };
        }
    }, [scrollToSection]);


    // Menu item animation

    useEffect(() => {
        const sections = document.querySelectorAll('.section_detect'); // select your sections

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.25 // 25% element visibility
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
               // console.log(entry.target.id, entry.isIntersecting); // We display the id and visibility state of each element

                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id); // it assumes you have an id for each section
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        sections.forEach((section) => {
            observer.observe(section);
        });

        // Removing an event handler when a component is unmounted
        return () => {
            sections.forEach((section) => {
                observer.unobserve(section);
            });
        };
    }, []);

    // Scrollbar animation

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const scrollHeight = document.documentElement.scrollHeight - windowHeight;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const calculatedScrollPercentage = (scrollTop / scrollHeight) * 100;
            if (scrollTop < 400) {
                setActiveSection(false)
            }
            setScrollPercentage(calculatedScrollPercentage);
        };

        window.addEventListener('scroll', handleScroll);

        // Removing an event handler when a component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const blockStyle = {
        width: `${scrollPercentage}%`,
    };


    return (
        <header className={'section-nav'}>
            <div className="container-header navigation">
                <a href="/">
                    <img src={Logo} alt="Web developer" className="logo"/>
                </a>

            <div className="hamburder">

                <svg className={`ham hamRotate ham8 ${isOpen ? 'active' : ''}`} viewBox="0 0 100 100" width="80"
                     onClick={handleClick}>
                    <path className="line top"
                          d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"/>
                    <path className="line middle" d="m 30,50 h 40"/>
                    <path className="line bottom"
                          d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"/>
                </svg>

            </div>
            <nav className={`${isOpen ? 'active' : ''}`}>
                <ul>
                    {menuItems.map((item, index) => (
                        <li key={item.id}>
                            <a
                                href={`/#${item.id}`}
                                className={activeSection === item.id ? 'active' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection(item.id); // вызываем функцию прокрутки при клике
                                }}
                            >
                                {index + 1}. {item.label}
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="media_mobile">
                    <MediaLinks />
                </div>
            </nav>
            <div style={blockStyle} className={'onscroll'}></div>
            </div>
        </header>
    );
};

export default Header;
import React, { useEffect, useState } from 'react';
import '../styles/modal.scss';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TelegramIcon from '../img/media/telegram_o.svg';
import InstagramIcon from '../img/media/insta.svg';
import MailOutlineIcon from '../img/media/email.svg';
import Linkedin_2 from '../img/media/linkedin.svg';
import Image from "./Image";
import emailjs from '@emailjs/browser';

const ModalCTA = ({ openAction, setOpenAction }) => {
    const [form, setForm] = useState({
        type: "",
        contact: ""
    });
    const [open, setOpen] = useState(openAction);
    const [type, setType] = useState('tel');
    const [placeholder, setPlaceholder] = useState('+1 (___) ___-__-__');
    const [errorState, setErrorState] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleClose = () => {
        setOpen(false)
        setOpenAction(false);
    };

    const changeContactType = (event) => {
        const contactType = event.target.value;
        setType(contactType)
        form.contact = '';
        setErrorMessage(false);

        switch (contactType) {
            case 'tel':
                setPlaceholder('+1 (___) ___-__-__')
                break;
            case 'email':
                setPlaceholder('myE-mail@gmail.com')
                break;
            case 'text':
                setPlaceholder('@contact_me or your phone')
                break;
            case 'url':
                setPlaceholder('https://www.linkedin.com/in/meyprofile/')
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setOpen(openAction);
    }, [openAction]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    const validFormPattern = (event) => {
        const { key, ctrlKey, metaKey } = event;
        let pattern;

        if (["Backspace", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab"].includes(key) || ((ctrlKey || metaKey)) || ((ctrlKey || metaKey))) {
            return;
        }

        switch (type) {
            case 'tel':
                pattern = /[0-9+\s-]/;
                break;
            case 'email':
                pattern = /[a-zA-Z0-9@._%+-]/;
                break;
            case 'text':
                pattern = /[a-zA-Z0-9\s@\-_]/;
                break;
            case 'url':
                pattern = /[a-zA-Z0-9:?&_=#/.-]/;
                break;
            default:
                pattern = /.*/; // Для других типов контакта разрешаем ввод всех символов
                break;
        }

        if (!pattern.test(key)) {
            event.preventDefault();
        }
    };

    const validForm = (event) => {
        const { name, value } = event.target;
        let isValid = true;
        setErrorMessage(false);
        setSuccessMessage(false);

        switch (type) {
            case 'tel':
                const phonePattern = /^\+?[0-9\s-]{8,}$/;
                isValid = phonePattern.test(value);
                break;
            case 'email':
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                isValid = emailPattern.test(value);
                break;
            case 'text':
                const textPattern = /^[a-zA-Z0-9\s@\-_]+$/;
                isValid = textPattern.test(value);
                break;
            case 'url':
                const urlPattern = /^(ftp|http|https):\/\/[^\s?&#=]+(?:\?(?:[^?\s#&]*&?)*)(?:#[^\s?&#=]*)?$/;
                isValid = urlPattern.test(value);
                break;
            default:
                break;
        }

        if (!isValid) {
            setErrorState(true);
        } else {
            setErrorState(false);
        }

        setForm((prevProps) => ({
            ...prevProps,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (errorState || !form.contact) {
            setSuccessMessage(false);
            setErrorMessage('Invalid input');
            return;
        }

        try {
            await emailjs.send(
                'service_ocu3ghj',
                'template_ewgno2y',
                {
                    from_name: 'feedback@cv.hi-webapp.com',
                    to_name: 'nikisshlife@gmail.com',
                    subject: 'New contact request',
                    message: `Contact type: ${type}\nContact: ${form.contact}`
                },
                {
                    publicKey: 'Sl71C1XEkewEcME1q',
                },
            );

            setErrorMessage(false);
            setSuccessMessage('Success. Keep in touch!');
            setForm({
                type: "",
                contact: ""
            });
        } catch (error) {
            setErrorMessage('Failed to send message');
        }

    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={'modal_container'}>
                    <div className="close_modal" onClick={handleClose}></div>

                    <h2>Grab my contacts</h2>

                    <div className={'modal_contacts'}>
                        <div className={'modal_contacts__links'}>
                            <a href="https://t.me/Haterobots"><Image src={TelegramIcon} alt={`telegram`}/></a>
                            <a href="https://www.instagram.com/happywebdev/"><Image src={InstagramIcon} alt={`instagram`}/></a>
                            <a href="https://www.linkedin.com/in/melnykmykyta/"><Image src={Linkedin_2} alt={`Linkedin`}/></a>
                            <a href="mailto:mykytam91@gmail.com"><Image src={MailOutlineIcon} alt={`email`}/></a>
                        </div>
                    </div>

                    <span className={'divider'}>or</span>

                    <div className="modal_form">
                        <form onSubmit={handleSubmit} noValidate>
                            <label htmlFor="contant_info">How can I get in touch with you?</label>
                            <div className='contact_type'>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue="tel"
                                    onChange={changeContactType}
                                >
                                    <FormControlLabel value="tel" control={<Radio sx={{color: '#FFF'}} />} label="Phone"/>
                                    <FormControlLabel value="email" control={<Radio sx={{color: '#FFF'}}/>} label="Mail" />
                                    <FormControlLabel value="text" control={<Radio sx={{color: '#FFF'}}/>} label="Telegram" />
                                    <FormControlLabel value="url" control={<Radio sx={{color: '#FFF'}}/>} label="Linkedin" />
                                </RadioGroup>
                            </div>
                            <div className='input_section'>
                                <input
                                    placeholder={placeholder}
                                    type={type}
                                    value={form.contact}
                                    name="contact"
                                    onChange={validForm}
                                    onKeyDown={validFormPattern}
                                    className={`${errorMessage ? 'error' : ''}`}
                                />
                                {errorMessage && <p className="error_message">{errorMessage}</p>}
                                {successMessage && <p className="success_message">{successMessage}</p>}
                            </div>
                            <button type='submit' className='form_send'>Send</button>
                        </form>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default ModalCTA;

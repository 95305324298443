import { useInView } from 'react-intersection-observer';

const useInViewBlock = () => {
    const [ref, inView] = useInView({
        threshold: 0.6,
    });

    return [ref, inView];
};

export default useInViewBlock;
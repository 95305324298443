import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSpring, animated } from 'react-spring';

import '../../styles/tabs.scss';
import SpeedBlock from "../SpeedBlock";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function TabsSpeed({ tabs }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Function to calculate x, y, and scale based on mouse position
    const calc = (x, y) => {
        const BUFFER = 60;
        const scale = 1.1; // Scale factor when hovered
        const why = -(y - window.innerHeight / 2) / BUFFER;
        const ex = (x - window.innerWidth / 2) / BUFFER;
        return [why, ex, scale];
    };

    // Define the transform function for react-spring
    const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

    // State and animation setup using react-spring
    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1], // Initial values: no rotation, no scale
        config: { mass: 1, tension: 350, friction: 20 }
    }));

    // Reset animation state when mouse leaves
    const fotoDefolt = () => {
        set({ xys: [0, 0, 1] });
    };

    if (!tabs || tabs.length === 0) {
        return <span>No tabs available</span>;
    }

    return (
        <div className={'tab_container tabs_speed'}>
            <Box sx={{ display: 'flex' }}>
                <Tabs
                    orientation='horizontal'
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderColor: 'divider' }}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>

                {tabs.map((tab, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        <SpeedBlock speed={tab.speed} />


                        <div className={`site_img`}>
                            <animated.div
                                className="site_img_container"
                                onMouseMove={e => {
                                    const {clientX: x, clientY: y} = e;
                                    set({xys: calc(x, y)});
                                }}
                                style={{transform: props.xys.interpolate(trans)}}
                                onMouseLeave={fotoDefolt}
                            >
                                <div className={`frame_img ${tab.class}`}>
                                    <div className="scroll_img_container">
                                        <img src={tab.img} alt="" className={'site_img_scroll'}/>
                                    </div>
                                </div>
                            </animated.div>
                        </div>

                    </TabPanel>
                    ))}
            </Box>
        </div>
    );
}

import React, {useState} from 'react';
import '../styles/cta.scss';
import Button from "./Button";
import ModalCTA from "./ModalCta";


const Cta = () => {
    const [openAction, setOpenAction] = useState(false);
    const handleOpen = () => {
        setOpenAction(true);
    };

    return (
        <div className={'container bc_cta'}>
            <div className={'section_detect home_section cta_section'} id='contacts'>
                <h2 className={'section_title'}>
                   4. Contact me
                </h2>

                <p>If you are interested in developing a website,<br />
                    please contact me using one of the contacts provided.</p>

                <div className={'cta-btns'}>
                    <Button text={'Download resume'} styleClass={'btn_red'}  link={'/cv_mykyta_melnyk.pdf'} downloadMod={'download'}/>
                    <Button text={'Get offer'} styleClass={''} onClick={handleOpen} />
                </div>
            </div>
            <ModalCTA openAction={openAction} setOpenAction={setOpenAction}/>
        </div>
    );
};

export default Cta;
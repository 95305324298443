import React from 'react';
import '../styles/main.scss';

const Button = ({ link, text, styleClass, onMouseEnter, onMouseLeave, onClick, downloadMod }) => {

    return (
        <a href={link} className={`main_btn ` + styleClass}
           onMouseEnter={onMouseEnter}
           onMouseLeave={onMouseLeave}
           onClick={onClick}
           download={downloadMod ? 'download' : null}
        >
            {text}
        </a>
    );
};

export default Button;

import React from 'react';
import ProjectItem from "./subComponents/ProjectItem";
import Button from "./Button";
import Alexdr from '../../src/img/projects/alex-d.jpg';
import Ph from '../../src/img/projects/ph.jpg';
import Prep from '../../src/img/projects/prep.jpg';

const Projects = () => {
    const projectData = [
        {
            image: Alexdr,
            title: 'AlexDRacing',
            description: 'Minimalistic portfolio website for a racer. Integrated unique <tag>photo gallery<tag> and <tag>video gallery<tag>. The shortest project development timeline.',
            tags: ['React', 'JavaScript', 'Styled components'],
            link: 'https://alexdracing.com/',
            project_name: 'Alexd',
        },
        {
            image: Ph,
            title: 'PaperHelp',
            description: 'Custom writing service website. <tag>Modern design<tag> and <tag>flexible functionality<tag>, customizable for the affiliate network.',
            tags: ['Smarty', 'JavaScript', 'PhP'],
            link: 'https://www.paperhelp.org/',
            project_name: 'PaperHelp',
        },
        {
            image: Prep,
            title: 'PrepMyPaper',
            description: 'Website for traffic conversion. <tag>Customizable WordPress theme<tag> with a user-friendly admin panel. The site <tag>increased sales by 30%<tag>.',
            tags: ['Wordress', 'JavaScript', 'PhP'],
            link: 'https://prepmypaper.com/',
            project_name: 'Alexd',
        },
    ];
    return (
        <div className='section_detect container projects_section' id={'portfolio'}>
            <div className={'home_section'}>
                <h2 className={'section_title'}>
                    3. Projects I’ve Built
                </h2>
                <div className='projects_container'>
                    {projectData.map((data, index) => (
                        <ProjectItem key={index} index={index} {...data} />
                    ))}
                </div>

                <div className='btn_center mt-section'>
                    <Button text={'More projects'} styleClass={''} link={'/projects'}/>
                </div>

            </div>
        </div>
    );
};

export default Projects;
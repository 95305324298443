import React, {useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ProjectItem from "../components/subComponents/ProjectItem";
import Button from "../components/Button";
import ModalCTA from "../components/ModalCta";
import MediaLinks from "../components/MediaLinks";

import Alexdr from "../img/projects/alex-d.jpg";
import Ph from "../img/projects/ph.jpg";
import Prep from "../img/projects/prep.jpg";
import Sempro from "../img/projects/sempro.jpg";
import Pechnik from "../img/projects/pechnik_main.jpg";


const ProjectsPage = () => {
    const [openAction, setOpenAction] = useState(false);
    const handleOpen = () => {
        setOpenAction(true);
    };

    const projectData = [
        {
            image: Pechnik,
            projecctLink : '/projects/pechnik',
            title: 'Pechnik',
            description: 'The website features galleries of completed works with a <tag>convenient filter<tag> and a functional application form. Photos undergo additional processing to <tag>enhance their quality<tag>.',
            tags: ['React', 'JavaScript', 'Styled components'],
            link: 'https://pechnik-24.ru/',
            project_name: 'Pechnik',
        },
        {
            image: Sempro,
            projecctLink : '/projects/sempro',
            title: 'SEMPRO CONFERENCE',
            description: 'A website for the world\'s most iconic Affiliate Conference. It features a complex design with <tag>numerous animations<tag> while maintaining ease of use and <tag>fast performance<tag>. An <tag>advanced ticket ordering form<tag> has been implemented.',
            tags: ['PHP', 'JavaScript', 'Styled components'],
            link: '',
            project_name: 'Sempro',
        },
        {
            image: Alexdr,
            projecctLink : '',
            title: 'AlexDRacing',
            description: 'Minimalistic portfolio website for a racer. Integrated unique <tag>photo gallery<tag> and <tag>video gallery<tag>. The shortest project development timeline.',
            tags: ['React', 'JavaScript', 'Styled components'],
            link: 'https://alexdracing.com/',
            project_name: 'Alexd',
        },
        {
            image: Ph,
            projecctLink : '',
            title: 'PaperHelp',
            description: 'Custom writing service website. <tag>Modern design<tag> and <tag>flexible functionality<tag>, customizable for the affiliate network.',
            tags: ['Smarty', 'JavaScript', 'PhP'],
            link: 'https://www.paperhelp.org/',
            project_name: 'PaperHelp',
        },
        {
            image: Prep,
            projecctLink : '',
            title: 'PrepMyPaper',
            description: 'Website for traffic conversion. <tag>Customizable WordPress theme<tag> with a user-friendly admin panel. The site <tag>increased sales by 30%<tag>.',
            tags: ['Wordress', 'JavaScript', 'PhP'],
            link: 'https://prepmypaper.com/',
            project_name: 'Alexd',
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>Projects | Mykyta Melnyk</title>
                <meta name="description" content='Portfolio of Mykyta Melnyk, showcasing a collection of innovative frontend projects. From responsive web designs to interactive user interfaces.' />
                <meta property="og:url" content="/projects" />
            </Helmet>

            <div className='section_detect container projects_section'>
                <div className={'home_section'}>
                    <h1 className={'section_title'}>
                       The best Projects
                    </h1>
                    <div className='projects_container'>
                        {projectData.map((data, index) => (
                            <ProjectItem key={index} index={index} {...data} />
                        ))}
                    </div>

                    <div className='btn_center mt-section'>
                        <Button text={'Get offer'} styleClass={''} onClick={handleOpen} />
                    </div>
                    <ModalCTA openAction={openAction} setOpenAction={setOpenAction}/>
                </div>
            </div>

            <MediaLinks/>
        </HelmetProvider>
    );
};

export default ProjectsPage;